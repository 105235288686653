import { Box, Card, CardContent, CircularProgress, Icon, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { useEffect, useState } from "react";
import useApi from "../hooks/useApi"
import apiClient from "../api/apiClient"
import { toast } from "react-toastify"
import Paginate from "./mui/Paginate"
import { formatDate, objectToQueryString } from "../modules/helpers"
import { ClientShopFilterSidebar } from "./sidebar-filter/ProductFilterSidebar";
import { Link } from "react-router-dom"

const ClientlistCard = () => {
  const [count, setCount] = useState(0)
  const { request, error, data, loading } = useApi(route => apiClient.get(route))
  const [showFilter, setShowFilter] = useState(false)
  const [date, setDate] = useState([])
  const [reset, setReset] = useState(false)
  const intialFilters = {
    start_date: "",
    end_date: "",
    keyword: "barcode",
    search: ""
  }
  const [filters, setFilters] = useState(intialFilters)
  const onReset = () => {
    setFilters(intialFilters)
    setDate([])
    setReset(true)
  }
  const onFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }
  useEffect(() => {
    if (reset) fetchData()
  }, [reset])
  const fetchData = async (page = 1) => {
    const filterObject = {
      start_date: date?.[0] || "",
      end_date: date?.[1] || "",
      [filters.keyword]: filters.search
    }
    if (page == 1 && count) setCount(0)
    const result = await request(
      `/client/?limit=10&page=${page}&${objectToQueryString(filterObject)}`
    )
    setReset(false)
    if (!result.ok) return toast.error("Error")
    //console.log(result)
    if (!count || page == 1) setCount(result.data.count)
  }
  const handlePageChange = (event, value) => {
    fetchData(value)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="px-3">
      <ClientShopFilterSidebar
        openFilter={showFilter}
        onOpenFilter={() => setShowFilter(true)}
        onCloseFilter={() => setShowFilter(false)}
        handleChange={onFilterChange}
        values={filters}
        onReset={onReset}
        fetchData={fetchData}
        date={date}
        setDate={setDate}
      />
      <div className=" d-flex justify-content-center flex-wrap">
        {!loading ? (
          <>
            {data?.map((item, index) => (
              <Box sx={{ width: 450 }}>
                <Link to={`/admin/customer-detail/${item._id}`} style={{ textDecoration: "none" }}>
                  <Card
                    className=""
                    key={index}
                    sx={{
                      height: 180,
                      margin: "5px",
                      borderRadius: 3,

                      cursor: "pointer",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      ":hover": {
                        transform: "scale(1.03)",
                        boxShadow: 6
                      }
                    }}>
                    <CardContent className=" " sx={{ backgroundColor: "#F1F1F5", padding: 1 }}>
                      <Box className="row align-items-start justify-content-between ">
                        <Box className="col-6 ">
                          <Box className="d-flex align-items-center ">
                            <Icon
                              className=""
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "60px"
                                // padding: '60px',
                                // margin: '0', // Adjust the line height as needed
                                // padding:'0'
                              }}>
                              <AccountCircleIcon sx={{ fontSize: "50px" }} />
                            </Icon>
                            <Box className="">
                              <Typography
                                className="fs_14px"
                                variant="p"
                                sx={{ fontWeight: "bold" }}
                                component="div">
                                Client Name
                              </Typography>
                              <Typography className="fs_10px" variant="p" component="div">
                                {item.firstname + " " + item.lastname}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="col-6 ">
                          <Typography sx={{ fontWeight: "bold" }}>Barcode</Typography>

                          <Typography sx={{ fontWeight: "sami-bold" }}>{item.barcode}</Typography>
                        </Box>
                      </Box>
                      <hr className="m-0" />
                      <Box className="row align-items-start justify-content-between ">
                        <Box className="col-6 ">
                          <Typography className="fs_14px mb-0 " sx={{ fontWeight: "bold" }}>
                            Address:
                          </Typography>
                          <Typography className="fs_10px">{item.address}</Typography>
                        </Box>

                        <Box className="col-6 ps-3">
                          <Typography className="fs_14px " sx={{ fontWeight: "bold" }}>
                            Pet Name:
                          </Typography>
                          <Typography className="fs_10px">{item.pet.pet_name}</Typography>
                        </Box>

                        <Box className="col-6">
                          <Typography className="fs_14px mb-0 " sx={{ fontWeight: "bold" }}>
                            Email:
                          </Typography>
                          <Typography className="fs_10px">{item.email}</Typography>
                        </Box>
                        <Box className="col-6 ps-3">
                          <Typography className="fs_14px " sx={{ fontWeight: "bold" }}>
                            Contact:
                          </Typography>
                          <Typography className="fs_10px">{item.phone_no}</Typography>
                        </Box>
                        <Box className="col-6">
                          <Typography className="fs_14px " sx={{ fontWeight: "bold" }}>
                            Registered At:
                          </Typography>
                          <Typography className="fs_10px">
                            {formatDate(item?.createdAt, "MMMM D, YYYY [at] h:mma")}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              </Box>
            ))}
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </div>
      {
        <div className={`${loading && "d-none"}`}>
          <Paginate style={{ color: "white" }} count={count} onChange={handlePageChange} />
        </div>
      }
    </div>
  )
}

export default ClientlistCard
