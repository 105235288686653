import { useRef } from "react"
import { useBarcode } from "next-barcode"
import { Divider, Typography } from "@mui/material"
import html2canvas from "html2canvas" // Import html2canvas library
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import OptionsMenus from "../components/core/OptionsMenu"
import BarcodeOptionMenu from "./barcode/BarcodeOptionMenu"
function BarcodeListArchive({ itemObj, index, ShowiIcon, url, ...props }) {
  const divRef = useRef(null)

  const handleDownload = async () => {
    // Convert the SVG to an image using html2canvas
    const canvas = await html2canvas(divRef.current, {
      scale: 2 // You can adjust the scale for better resolution
    })
    //console.log(canvas);

    // Convert the canvas to a data URL (PNG format)
    const dataUrl = canvas.toDataURL("image/png")

    // Create a temporary anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = dataUrl
    anchorElement.download = "barcode_image.png" // Specify the filename for the downloaded image

    // Programmatically click the anchor element to trigger the download
    anchorElement.click()
  }

  return (
    <div className="">
      <Barcode
        url={url}
        ShowiIcon={ShowiIcon}
        key={index}
        activated={itemObj?.activated}
        value={itemObj.key}
        handleDownload={handleDownload}
        divRef={divRef} // Pass the ref to the Barcode component
        code_disabled={itemObj.code_disabled}
        {...props}
      />
    </div>
  )
}

function Barcode({
  value,
  ShowiIcon,
  url,
  divRef,
  handleDownload,
  activated,
  code_disabled,
  ...props
}) {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      background: "#F5F5F5",
      width: 2
    }
  })

  return (
    <div
      className="p-2"
      style={{
        backgroundColor: "#F1F1F5",
        maxWidth: "240px",
        borderRadius: "10px",
        position: "relative"
      }}>
      <div className="row align-items-center">
        <div className="col-6">
          {activated && <CheckCircleIcon style={{ color: "green", fontSize: 30 }} />}
        </div>

        <div className="col-6 d-flex justify-content-end">
          <BarcodeOptionMenu
            handleBarcodeDisableToggle={props.handleBarcodeDisableToggle}
            disabledAlready={code_disabled}
            // reportButtonsDisable={sampleStatus !== "SUB"}
          />
        </div>
      </div>

      <div
        ref={divRef}
        className="p-2"
        style={{
          backgroundColor: "#F1F1F5",
          maxWidth: "240px",
          borderRadius: "10px",
          opacity: code_disabled ? 0.3 : 1
        }}>
        <div className="d-flex">
          <svg
            style={{ width: "100%", maxWidth: "100px" }} // Increase the width as needed
            ref={inputRef}
          />
        </div>
        <Divider sx={{ margin: 0 }} />
        <Typography className="text-center" variant="body2" style={{ maxWidth: "300px" }}>
          <span
            className="text-wrap"
            target="_blank"
            style={{
              display: "block",
              wordWrap: "break-word",
              textDecoration: "none",
              color: "black"
            }}>
            {url}
          </span>
        </Typography>
      </div>
    </div>
  )
}

function BarcodeOriginal({ value, url }) {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      background: "#F5F5F5",
      width: 2,
      height: 50
    }
  })

  return (
    <div
      style={{
        backgroundColor: "#F3EFEF",
        width: "57mm",
        height: "32mm"
      }}>
      <div className="d-flex justify-content-center">
        <svg style={{ width: "100%" }} height="22mm" ref={inputRef} />
      </div>
      <div className="text-center" style={{ height: "10mm" }}>
        <span style={{ color: "black" }}>{url}</span>
      </div>
    </div>
  )
}

export { BarcodeOriginal }
export default BarcodeListArchive
