import { Box, Button, Grid, Paper, Typography, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import apiClient from "../../api/apiClient"
import BackButton from "../../components/mui/BackButton"

import Modal from "react-bootstrap/Modal"
import HelpIcon from "@mui/icons-material/Help"
import { useDispatch } from "react-redux"
import { showModal } from "../../redux/modalSlice"

function ClientDetailAdmin() {
  const [data, setData] = useState()
  const { id } = useParams()
  //console.log(id,"asasasasasasasasa")
  const fetchData = async () => {
    const result = await apiClient.get(`/client/${id}`)
    if (!result.ok) {
    } else {
      setData(result.data.result)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [showNotesModal, setShowNotesModal] = useState(false)

  const addNotes = () => {}

  return (
    <div>
      <Grid container className="d-flex justify-content-center ">
        <Grid className="" item md={10} sx={{ backgroundColor: "" }} xs={12}>
          <Grid className=" d-flex justify-content-center align-items-center">
            <Grid container md={10} xs={12} spacing={2}>
              <Grid container className="mt-4" sx={{ py: 1 }}>
                <Grid className=" px-2 d-flex align-items-center" item md={5} xs={12}>
                  <div>
                    <BackButton to={"/tn/samples"} />
                  </div>
                  <Box className="ps-3 fw-bold " sx={{ display: "inline" }}>
                    Samples Details:
                  </Box>
                </Grid>

                <Grid className="px-2  d-flex justify-content-end" item md={7} xs={12}>
                  <Button
                    onClick={() => setShowNotesModal(true)}
                    className="m-2  btn btn_bg_secondery fs_10px same-size-button white_space"
                    sx={{ paddingLeft: 5, paddingRight: 5 }}
                    variant="outlined"
                    color="primary">
                    Notes for Manager
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {data?.veterinary_info && (
            <Grid className=" d-flex justify-content-center">
              <Grid
                className=" border"
                container
                md={10}
                xs={12}
                spacing={2}
                component={Paper}
                elevation={5}
                sx={{ marginTop: 1, padding: 5, borderRadius: 7 }}>
                <Box className=" text-start w-100">
                  <Typography
                    className="fw-bold "
                    variant="body2"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                    Veterinary Clinic Information:
                  </Typography>
                </Box>
                <Box mt={3}>
                  <hr style={{ backgroundColor: "red" }} />
                </Box>
                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Clinic Name
                    </Typography>
                    <Typography className="fs_16px " variant="body2" sx={{}}>
                      {data?.veterinary_info.clinic_name}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Clinic Email
                    </Typography>
                    <Typography className="fs_16px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                      {data?.veterinary_info.clinic_email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Veterinarian
                    </Typography>
                    <Typography className="fs_16px " variant="body2" sx={{}}>
                      {data?.veterinary_info.veterinarian}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className=" d-flex justify-content-center">
            <Grid
              className=" border"
              container
              md={10}
              xs={12}
              spacing={2}
              component={Paper}
              elevation={5}
              sx={{ marginTop: 1, padding: 5, borderRadius: 7 }}>
              <Box className=" text-start w-100">
                <Typography
                  className="fw-bold "
                  variant="body2"
                  sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                  Pet Parent Information:
                </Typography>
              </Box>
              <Box mt={3}>
                <hr style={{ backgroundColor: "red" }} />
              </Box>
              <Grid container sx={{ py: 2, rowGap: 6 }}>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    First name
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.firstname}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Last name
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                    {data?.lastname}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Email
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.email}
                  </Typography>
                </Grid>
                {data?.email_2 && (
                  <Grid item md={6} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      2nd Email Recipient
                    </Typography>
                    <Typography className="fs_16px" variant="body2" sx={{}}>
                      {data?.email_2}
                    </Typography>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Phone number
                  </Typography>
                  <Typography className=" fs_16px" variant="body2" sx={{}}>
                    {data?.phone_no}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Street Address
                  </Typography>
                  <Typography className="fs_16px" variant="body2" sx={{}}>
                    {data?.address}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className=" fs_16px" variant="body2" sx={{ fontWeight: "bold" }}>
                    Postal Code
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.zip}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    City
                  </Typography>
                  <Typography className="fs_16px" variant="body2" sx={{}}>
                    {data?.city}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className=" fs_16px" variant="body2" sx={{ fontWeight: "bold" }}>
                    State
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.state}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Country
                  </Typography>
                  <Typography className="fs_16px" variant="body2" sx={{}}>
                    {data?.country}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className=" d-flex justify-content-center">
            <Grid
              className=" "
              container
              md={10}
              xs={12}
              spacing={2}
              component={Paper}
              elevation={5}
              sx={{ marginTop: 5, padding: 5, borderRadius: 7 }}>
              <Box className="text-start w-100">
                <Typography
                  className="fw-bold "
                  variant="body2"
                  sx={{ fontSize: "1rem", textTransform: "uppercase" }}>
                  Pet Information:
                </Typography>
              </Box>
              <Box mt={3}>
                <hr style={{ backgroundColor: "red" }} />
              </Box>
              <Grid container sx={{ py: 2 }}>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px" variant="body2" sx={{ fontWeight: "bold" }}>
                    Barcode
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.barcode.key}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Created Date
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{ fontSize: "0.7rem" }}>
                    {data?.createdAt ? new Date(data.createdAt).toISOString().split("T")[0] : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ py: 2 }}>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Pet name
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.pet?.pet_name}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* <FormControlLabel control={<Checkbox  />} label="Dog"  sx={{
                     fontSize: '10px', // Adjust the font size as needed
                  }} /> */}
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Pet Type
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.pet?.type}
                    {/* uli */}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* <FormControlLabel control={<Checkbox  />} label="Male" /> */}
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Gender
                  </Typography>
                  <Typography className="fs_16px " variant="body2" sx={{}}>
                    {data?.pet?.gender_value}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ py: 2 }}>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Birthday
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.dob}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Age
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.age}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Breed
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.breed}
                  </Typography>
                </Grid>
                <Grid sx={{ py: 2 }} item md={4} xs={12}>
                  <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                    Birth place{" "}
                  </Typography>
                  <Typography className="fs_16px " variant="body2">
                    {data?.pet?.birth_place}
                  </Typography>
                </Grid>
                {data?.notes_for_managers && (
                  <Grid sx={{ py: 2 }} item md={4} xs={12}>
                    <Typography className="fs_16px " variant="body2" sx={{ fontWeight: "bold" }}>
                      Notes for Managers
                    </Typography>
                    <Typography className="fs_16px " variant="body2">
                      {data?.notes_for_managers}
                    </Typography>
                  </Grid>
                )}
                {/* <Grid sx={{py:2}} item md={4} xs={12}>
                <Typography className=' ' variant="body2" sx={{ fontSize: '1rem' }}>
                Medication             </Typography>
                <Typography className=' ' variant="body2" sx={{ fontSize: '0.7rem' }}>
                {data?.pet?.medication}
                </Typography>
                </Grid> */}
              </Grid>

              {/* <Typography className='text_color ' variant="body2" sx={{ fontSize: '1rem', textTransform:'uppercase' }}>
                 Pet diet:
                </Typography> */}
              {/* <Grid container sx={{py:2}}>
                <Grid item md={6} xs={12}>
                <FormControlLabel control={<Checkbox  />} label="Dry Kibble:" />
                <FormControlLabel control={<Checkbox  />} label="Raw:" />
                <FormControlLabel control={<Checkbox  />} label="Home Cooked :" />
                </Grid>
             </Grid> */}
              <Typography className=" fs_16px" variant="body2" sx={{}}>
                Agreed With Terms & Conditions.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showNotesModal && (
        <NotesModal
          show={setShowNotesModal}
          onHide={() => {
            setShowNotesModal(false)
          }}
          fetchData={fetchData}
          data={data}
        />
      )}
    </div>
  )
}
export default ClientDetailAdmin

function NotesModal({ fetchData, data, onHide, show }) {
  let initialstate = {
    notes_for_managers: data.notes_for_managers || ""
  }
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialstate)
  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const result = await apiClient.put(`/client/${data._id}/`, formData)
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Notes",
          message: result.data.message
        })
      )
    fetchData()
    onHide()
    return dispatch(
      showModal({
        error: false,
        title: "Notes Added"
        // message: result.data.message
      })
    )
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className=" " closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100">
          <h4 className="text-center">Order Notes</h4>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {/* <h4 className="text-center">Order Notes</h4> */}

          <div className="row justify-content-center gap-2 my-2">
            <div className="col-12">
              <TextField
                // required
                label="Notes For Managers"
                // value={client.veterinarian}
                name="notes"
                value={formData.notes_for_managers}
                onChange={e => handleChange("notes_for_managers", e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                rows={5}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            type="submit"
            size="large"
            // onClick={()=>handleSubmit()}
            variant="contained"
            className=" btn_bg_secondery px-5">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
