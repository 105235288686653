import "./App.css"
import { Route, Routes } from "react-router-dom";
import {
  AddnewClient,
  AddnewTechnicians,
  Barcode,
  Client,
  Dashboard,
  Login,
  Navbar,
  NotFound,
  PetBio,
  Samples,
  Technicians
} from "./Pages"
import ClientPetBio from "./Pages/admin/ClientDetailAdmin"
import ClientDashboard from "./Pages/client/ClientDashboard"
import Home from "./Pages/Home"
import PetBioRegistration from "./Pages/client/PetBioRegistration"
import CreateReport from "./Pages/technician/CreateReport"
import GetReport from "./Pages/client/GetReport"
import CreateReportPreview from "./Pages/technician/CreateReportPreview"
import ProtectedRoute from "./components/ProtectedRoute"
import { useDispatch, useSelector } from "react-redux"
import ToastModal from "./components/ToastModal"
import { hideModal } from "./redux/modalSlice"
import Profile from "./Pages/Profile"
import { AdminReports } from "./Pages/admin/AdminReports"
import SubAdmin from "./Pages/admin/SubAdmin"
import AddnewSubadmin from "./Pages/admin/AddnewSubadmin"
import SubmittedReport from "./Pages/SubmittedReport"
import ClientSubmittedReport from "./Pages/client/ClientSubmittedReport"
import ArchiveBarcode from "./Pages/ArchiveBarcode"
import { ThemeProvider, createTheme } from "@mui/material"
import TestBarcode from "./Pages/TestBarcodes"
import ClientAnalytics from "./Pages/admin/ClientAnalytics";
import TechnicianSamples from "./Pages/admin/Samples";
import Settings from "./Pages/admin/Settings";

function App() {
  // useNotOnPages(["/report/","/final-report/"])
  const dispatch = useDispatch()
  const {
    modal,
    auth: { userType }
  } = useSelector(state => state)

  const handleClose = () => {
    dispatch(hideModal())
  }
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          asterisk: { color: "red" }
        }
      }
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <ToastModal values={modal} onHide={handleClose} />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/test" element={<TestBarcode />} />
        <Route index element={<Home />} />
        <Route
          element={
            <ProtectedRoute>
              <Navbar />{" "}
            </ProtectedRoute>
          }
        >
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/barcode" element={<Barcode />} />
          <Route path="/admin/client" element={<Client />} />
          <Route path="/admin/samples" element={<Samples />} />
          {userType === "AD" && <Route path="/admin/technicians" element={<Technicians />} />}
          {userType === "AD" && <Route path="/admin/sub-admin" element={<SubAdmin />} />}
          <Route path="/admin/sub-admin/addnew-subadmin" element={<AddnewSubadmin />} />
          <Route path="/admin/technicians/addnew-technicians" element={<AddnewTechnicians />} />
          <Route path="/admin/pet-bio/:id" element={<PetBio />} />
          <Route path="/admin/addnew-client" element={<AddnewClient />} />
          <Route path="/admin/profile" element={<Profile role={"AD"} />} />
          <Route path="/admin/settings" element={<Settings  />} />
          <Route path="/admin/report-settings" element={<AdminReports />} />
          <Route path="/admin/reports" element={<SubmittedReport />} />
          <Route path="/admin/analytics" element={<ClientAnalytics />} />
          <Route path="/admin/customer-detail/:id" element={<ClientPetBio />} />
        </Route>

        <Route
          element={
            <ProtectedRoute>
              {" "}
              <Navbar type={"client"} />{" "}
            </ProtectedRoute>
          }
        >
          <Route path="/tn/dashboard" element={<ClientDashboard />} />
          <Route path="/tn/profile" element={<Profile />} />
          <Route path="/tn/barcode" element={<ArchiveBarcode />} />
          <Route path="/tn/samples" element={<TechnicianSamples />} />
          {/* <Route path="/tn/rec-samples" element={<ClientRecSample />} /> */}
          <Route path="/tn/report-submitted" element={<ClientSubmittedReport />} />
          <Route path="/tn/customer-detail/:id" element={<ClientPetBio />} />
          <Route path="/tn/report/:barcode" element={<CreateReport />} />
          <Route path="/tn/final-report/:barcode" element={<CreateReportPreview />} />
        </Route>

        <Route>
          <Route path="/get-report/:barcode" element={<GetReport />} />
          {/* <Route path='/test-report/:barcode' element={<TestReport/>}/> */}

          <Route path="/petbio-registration/:id" element={<PetBioRegistration />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  )
}

export default App
